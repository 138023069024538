import type {ApolloError} from '@apollo/client';
import {notify} from '@kyvg/vue3-notification';

export {notify};

export function notifyGraphQLError(error: ApolloError, options: {message?: string} = {}) {
  let text = options.message ?? 'Oeps, er ging iets mis';

  if (error.message === 'validation') {
    // Optimistic logging of first available validation message.
    text = Object.entries(error.graphQLErrors[0].extensions.validation)[0][1][0];
  }

  notify({
    text,
    type: 'error',
  });
}
